(function () {
    /**
     * ----------------------
     * ---- VUE COMPONENT ---
     * ----------------------
     *
     * - Search Box
     * -- Used for basic trail searches with "places" listing
     *
     * - Note: As it's used everywhere, we need to keep it small and without dependencies nor polyfills. JS vanilla.
     *
     * - EVENT API (fire)
     * -- {COMPONENT_NAME}:searchPlace -> the user has selected a place to search
     * -- {COMPONENT_NAME}:searchText -> the user has selected a text to search
     *
     */

    Vue.component("search-box", {
        props: ["i18n", "alwaysDesktopMode"],
        data: function () {
            return {
                isModalOpen: false,
                text: "",
                recentSearches: [
                    //shoud be inside a size 1 array, for reusing the template. It's a "virtual" Group DTO
                    {
                        header: this.i18n.txtRecentSearches.toUpperCase(),
                        maxItems: MAX_SAVED_SEARCHES,
                        items: [], //ITEMS DTO, max 5 results
                    },
                ],
                placesSearch: [],
                search_geocoder_timeout: null,
                //For responsive measures
                widthScreenPx: 0,
                heightScreenPX: 0,
                headerHeightPx: 0,
                footerHeightPx: 0,
                isLoadingResults: false,
            };
        },
        template: `
 <!-- Search Box -->
   <div class="search-box-wrapper">
    <div class="search-box" :class="searchBoxClassCss" @mousedown="clickIn" ref="root">
      
      <!-- Header-->
      <!-- - Header Desktop -->
      <div v-if="!isResponsive || isModalOpen || alwaysDesktopMode" class="search-box__main" ref="header">
        <input class="search-box__input" :class="searchBoxInputDynamicClassCss" v-model="text" :placeholder="placeholderText" ref="input">
        <!-- Close -->
        <div v-if="isCloseButtonShow" class="search-box__close">
          <button type="button" class="search-box__close-button" aria-label="Close" @click.stop="closeModalByButton">
            <img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/search/modal_cross.svg">
          </button>
        </div>
      </div>
      
      <!-- - Header Responsive -->
      <div v-if="isResponsive && !isModalOpen && !alwaysDesktopMode"><span class="search-box__button-small-icon"></span></div>
      
      <!-- Modal -->
      <transition name="search-box__animationdown">
        <div v-if="isModalOpen" class="search-box__results">
          <div class="search-box__results-inner">       
            <ul class="search-box__list" ref="scrollbarContainer" @mousedown="unFocus" @touchstart="unFocus" :style="heightListResponsiveStyleCss">
              
              <!--  Nearby trails -->
              <div v-if="!isGeocoderSearch" class="search-box-item__first" :class="firstItemClassCss" @click.stop="nearbyTrails">
                 <span class="icon"><img class="icon-personal search-box-item__place-icon" src="https://sc.wklcdn.com/wikiloc/assets/styles/images/geocoder/geocoder_area.svg"></span>
                 <p>{{i18n.txtNearbyTrails}}</p>
              </div>
              <div v-if="isGeocoderSearch" class="search-box-item__first" :class="firstItemClassCss" @click.stop="searchTrails(textItem)">
                 <span class="icon"><img :src="textItem.iconUrl"></span>
                 <p v-html="textItem.htmlName"></p>
              </div>
              
              
              <!-- Dynamic results (loaded) -->
              <li v-show="!isShowSpinner" class="search-box-item" v-for="result in resultsToShow">
                 <span class="search-box-item__country">{{result.header}}</span>
                 <ul class="search-box-item__results">
                    <li class="search-box-item__place" v-for="item in result.items" @click.stop="searchTrails(item)">
                      <span class="icon"><img :class="resultIconSvgOpacityStyleCss(item)" :src="item.iconUrl" class="search-box-item__place-icon"></span>
                        <div class="search-box-item__place__content">
                          <span v-html="item.htmlName"></span>
                          <small v-if="item.context">, {{item.context}}</small>
                        </div>
                    </li>
                 </ul>
              </li>
              
              <!-- Dynamic results loading spinner -->
              <li v-show="isShowSpinner" class="search-box-item-spinner">
                <div class="spinner__round spinner__round--small"></div>
              </li>
              
            </ul>
            <div class="search-box__footer" ref="footer" :style="footerHideVisibilityStyleCss" @click.stop="searchTrails(userItem)">
              <span class="icon"><img :src="userItem.iconUrl"></span>
              <p v-html="userItem.htmlName"></p>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
`,
        methods: {
            closeModal: closeModal,
            closeModalByButton: closeModalByButton,
            clickIn: clickIn,
            onMousedownOutside: onMousedownOutside,
            searchTrails: searchTrails,
            nearbyTrails: nearbyTrails,
            readScreenDimensions: readScreenDimensions,
            readHeaderAndFooterDimensions: readHeaderAndFooterDimensions,
            unFocus: unFocus,
            textSearchWithText: textSearchWithText,
            resultIconSvgOpacityStyleCss: resultIconSvgOpacityStyleCss,
        },
        computed: {
            isResponsive: isResponsive,
            isCloseButtonShow: isCloseButtonShow,
            isShowSpinner: isShowSpinner,
            hasResultsToShow: hasResultsToShow,
            resultsToShow: resultsToShow,
            minCharactersGeocoderSearch: minCharactersGeocoderSearch,
            isGeocoderSearch: isGeocoderSearch,
            textItem: textItem,
            userItem: userItem,
            placeholderText: placeholderText,
            searchBoxClassCss: searchBoxClassCss,
            firstItemClassCss: firstItemClassCss,
            searchBoxInputDynamicClassCss: searchBoxInputDynamicClassCss,
            footerHideVisibilityStyleCss: footerHideVisibilityStyleCss,
            heightListResponsiveStyleCss: heightListResponsiveStyleCss,
        },
        watch: {
            text: text,
        },
        //VUE LIFECYCLE
        beforeMount: beforeMount,
        mounted: mounted,
        beforeDestroy: beforeDestroy,
    });

    /**
     * -----------------
     * ---- CONSTANTS --
     * -----------------
     */

    const EVENT_COMPONENT_ID = "search-box";
    const EVENT_NAMES_LISTEN = {
        MAIN_SEARCH_SHOW_PLACE: "main-search:showPlace",
        MAIN_SEARCH_CLEAR_PLACE: "main-search:clearPlace",
        USER_JSP_SHOW_USER: "findPeople:showUser",
    };

    const EVENT_NAMES_FIRE = {
        SEARCH_PLACE: EVENT_COMPONENT_ID + ":searchPlace",
        SEARCH_TEXT: EVENT_COMPONENT_ID + ":searchText",
    };

    const LOCAL_STORAGE_ID = "search-box";
    const LOCAL_STORAGE_KEYS = {
        SAVED_SEARCHES: LOCAL_STORAGE_ID + ":saved-searches", //array of Item DTOs
    };

    const MAX_SAVED_SEARCHES = 5;
    const RESPONSIVE_WIDTH_THRESHOLD_PX = 768;
    const NEARBY_TRAILS_DISTANCE_BBOX_METERS = 7000;

    const URLS = {
        FIND_PEOPLE: "/wikiloc/findPeople.do",
    };

    const AJAX_URLS = {
        GEOCODE_WHATISCLICKED: "/wikiloc/geocode.do?event=whatIsClicked&crc=",
        GEOCODE_SEARCH: "/wikiloc/geocode.do?event=searchJSONMap&name=",
    };

    const MIN_CHARS_GEOCODER_SEARCH_NORMAL = 3;
    const MIN_CHARS_GEOCODER_SEARCH_MIN = 1;

    /**
     * -----------------
     * ---- METHODS ----
     * -----------------
     * https://vuejs.org/v2/guide/instance.html#Data-and-Methods
     */

    function closeModal() {
        if (!this.isModalOpen) {
            return;
        }

        this.isModalOpen = false;
        if (this.isResponsive) {
            _enableDocumentScroll(true);
            this.$eventBus.$emit("resumeMapSearches"); //We need to resume the paused searches by moving map (beware of responsive map resizes)
        }
    }

    function closeModalByButton() {
        this.text = "";
        this.closeModal();

        //Special behaviour findPeople
        if (typeof INJECT !== "undefined" && INJECT.isFindPeople === true) {
            window.location.href = URLS.FIND_PEOPLE;
        }
    }

    function clickIn() {
        if (this.isModalOpen) {
            return;
        }

        if (this.isResponsive) {
            this.$eventBus.$emit("pauseMapSearches"); //We need to Pause the moving map searches (beware of responsive map resizes on Android with keyboard popup)
        }

        this.isModalOpen = true;

        if (this.isResponsive) {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 100);
            this.readHeaderAndFooterDimensions();
            _enableDocumentScroll(false);
            this.$nextTick(() => {
                _focusResponsive(this.$refs.input);
            });
        }
        gtagEvent("search_box_click", {
            ref: this.isResponsive ? "header_mobile" : "header",
        });

        fbq("trackCustom", "search_box_click", {
            ref: this.isResponsive ? "header_mobile" : "header",
        });
    }

    function onMousedownOutside(event) {
        var component = this.$refs.root;
        //The click is in the component
        if (
            !component ||
            component.contains(event.target) ||
            event.target.classList.contains("search-box__button-small-icon")
        ) {
            return;
            //Hide
        } else {
            this.closeModal();
        }
    }

    function searchTrails(item) {
        _saveRecentSearch(item, this.recentSearches[0].items);
        _logSearchMetrics(this.text, item);
        if (typeof INJECT !== "undefined" && INJECT.isWorldMap === true) {
            //don't reload screen
            if (item.type === GLOBAL_SEARCH.DTOS.ITEM_TYPE.PLACE) {
                var payload = {
                    place: item.name,
                    sw: item.sw,
                    ne: item.ne,
                };
                this.$eventBus.$emit(EVENT_NAMES_FIRE.SEARCH_PLACE, payload);
            } else if (item.type === GLOBAL_SEARCH.DTOS.ITEM_TYPE.TEXT) {
                this.$eventBus.$emit(EVENT_NAMES_FIRE.SEARCH_TEXT, {
                    text: item.name,
                });
                //On text we reset the input text
                this.text = "";
            } else if (item.type === GLOBAL_SEARCH.DTOS.ITEM_TYPE.USER) {
                gtagEvent("search", { type: "user", search_term: item.name });
                window.location.href = item.url; //users always to other page
            }
        } else {
            if (item.type === GLOBAL_SEARCH.DTOS.ITEM_TYPE.USER) {
                gtagEvent("search", { type: "user", search_term: item.name });
            }
            window.location.href = item.url;
        }

        //Close modal
        this.closeModal();
    }

    /**
     * Nearby Trails search by user location detection of the browser
     */
    function nearbyTrails() {
        navigator.geolocation.getCurrentPosition((position) => {
            _nearbyTrailsSearchOK(position, this);
        }, _nearbyTrailsSearchKO);
    }

    /**
     * We remove the focus from the input text.
     */
    function unFocus() {
        this.$refs.input.blur();
    }

    /**
     * We get the screen dimensions to use them as part of computed properties
     */
    function readScreenDimensions() {
        this.heightScreenPX = window.innerHeight;
        this.widthScreenPx = window.innerWidth;
    }

    function readHeaderAndFooterDimensions() {
        this.$nextTick(() => {
            try {
                this.headerHeightPx = this.$refs.header.clientHeight;
                this.footerHeightPx =
                    this.$refs.footer.getBoundingClientRect().height;
            } catch (e) {
                //async failbacks go there, silent crash
            }
        });
    }

    function textSearchWithText(name) {
        return this.i18n.txtWithText.replace("{0}", name);
    }

    function resultIconSvgOpacityStyleCss(item) {
        var cssClass = "";

        if (
            item.type == GLOBAL_SEARCH.DTOS.ITEM_TYPE.USER ||
            item.type == GLOBAL_SEARCH.DTOS.ITEM_TYPE.TEXT
        ) {
            cssClass = "search-box-item__place-icon--opacity";
        }

        return cssClass;
    }

    /**
     * ---- COMPUTED ATTRIBUTES ----
     * https://vuejs.org/v2/guide/computed.html#Computed-Properties
     */

    function isResponsive() {
        return this.widthScreenPx <= RESPONSIVE_WIDTH_THRESHOLD_PX;
    }

    function isCloseButtonShow() {
        return (
            this.text.length > 0 ||
            (this.isResponsive && !this.alwaysDesktopMode) ||
            (this.isResponsive && this.alwaysDesktopMode && this.isModalOpen)
        );
    }

    function isShowSpinner() {
        return this.isLoadingResults && this.isGeocoderSearch;
    }

    function hasResultsToShow() {
        return this.resultsToShow.length > 0;
    }

    //Decide what kind of results show in the search bar
    function resultsToShow() {
        var result = [];
        if (this.isGeocoderSearch) {
            result = this.placesSearch;
        } else if (this.recentSearches[0].items.length > 0) {
            result = this.recentSearches;
        }
        return result;
    }

    /**
     * Non latin characters can have full meanings with two characters
     */
    function minCharactersGeocoderSearch() {
        var minChars = MIN_CHARS_GEOCODER_SEARCH_NORMAL;

        //Check if latin (source: https://stackoverflow.com/questions/24107993/how-to-detect-non-roman-characters-in-js)
        var regIsNotLatin =
            /[^\u0000-\u024F\u1E00-\u1EFF\u2C60-\u2C7F\uA720-\uA7FF]/g;
        if (regIsNotLatin.test(this.text)) {
            minChars = MIN_CHARS_GEOCODER_SEARCH_MIN;
        }

        return minChars;
    }

    function isGeocoderSearch() {
        return this.text.length >= this.minCharactersGeocoderSearch;
    }

    function textItem() {
        var name = GLOBAL_METHODS.escapeEntities(this.text);
        var htmlName = this.i18n.txtSearchByText.replace("{0}", name);
        var url =
            "/wikiloc/map.do?sw=-89.999%2C-179.999&ne=89.999%2C179.999&q=" +
            encodeURIComponent(this.text) +
            "&page=1&fitMapToTrails=1";
        var iconUrl =
            "https://sc.wklcdn.com/wikiloc/assets/styles/images/search/text_icon_gray.svg";
        return new GLOBAL_SEARCH.DTOS.Item(
            0,
            name,
            htmlName,
            null,
            iconUrl,
            url,
            GLOBAL_SEARCH.DTOS.ITEM_TYPE.TEXT,
            null,
            null,
            null
        );
    }

    function userItem() {
        var name = GLOBAL_METHODS.escapeEntities(this.text);
        var htmlName = this.i18n.txtSearchByUser.replace("{0}", name);
        var url = URLS.FIND_PEOPLE + "?name=" + encodeURIComponent(name);
        var iconUrl =
            "https://sc.wklcdn.com/wikiloc/assets/styles/images/search/user_icon_gray.svg";
        return new GLOBAL_SEARCH.DTOS.Item(
            0,
            name,
            htmlName,
            null,
            iconUrl,
            url,
            GLOBAL_SEARCH.DTOS.ITEM_TYPE.USER,
            null,
            null,
            null
        );
    }

    function searchBoxClassCss() {
        var classCss = "";
        if (this.isResponsive && !this.alwaysDesktopMode) {
            classCss += "search-box--responsive ";
        }
        if (this.isModalOpen) {
            classCss += "search-box--open ";
        }
        return classCss;
    }

    function firstItemClassCss() {
        var classCss = "";

        if (this.resultsToShow.length == 0) {
            classCss = "search-box-item__first--alone";
        }

        return classCss;
    }

    function searchBoxInputDynamicClassCss() {
        var classCss = "";

        if (this.isModalOpen) {
            classCss = "search-box__input--open";
        }

        return classCss;
    }

    function placeholderText() {
        var text = this.i18n.txtSearchPlaceholder;

        if (this.isModalOpen) {
            text = this.i18n.txtSearchExamples;
        }

        return text;
    }

    function footerHideVisibilityStyleCss() {
        var styleCss = {};

        if (!this.isGeocoderSearch) {
            if (this.isResponsive) {
                styleCss = {
                    opacity: 0,
                };
            } else {
                styleCss = {
                    display: "none",
                };
            }
        }

        return styleCss;
    }

    /**
     * Height in Responsive mode should be static to allow fullscreen mode
     * that works in mobile devices due to BUG in 100% or 100vh
     * NOTE: we can't do this with only CSS
     */
    function heightListResponsiveStyleCss() {
        var styleCss = {};

        if (this.isModalOpen && this.isResponsive) {
            var offset = this.headerHeightPx;
            if (this.isGeocoderSearch) {
                //has footer
                offset = offset + this.footerHeightPx;
            }

            var height = this.heightScreenPX - offset;
            styleCss = {
                top: this.headerHeightPx + "px",
                height: height + "px",
            };
        }

        return styleCss;
    }

    /**
     * ------------------
     * ---- WATCHERS ----
     * ------------------
     * When Vue reactivity is not enough
     * https://vuejs.org/v2/guide/computed.html#Watchers
     */

    function text(newValue, oldValue) {
        //Fetch places results
        if (
            newValue.length >= this.minCharactersGeocoderSearch &&
            newValue != oldValue
        ) {
            _searchPlaces.call(this, this.i18n);
        }
    }

    /**
     * -----------------------
     * ---- VUE LIFECYCLE ----
     * -----------------------
     * https://vuejs.org/v2/guide/instance.html#Lifecycle-Diagram
     */

    /**
     * Called right before the mounting begins: the render function is about to be called for the first time.
     */
    function beforeMount() {
        // Remove old images from local Storage
        var item = localStorage.getItem(LOCAL_STORAGE_KEYS.SAVED_SEARCHES);
        if (item) {
            var savedSearches = JSON.parse(item);
            for (var i = 0; i < savedSearches.length; i++) {
                var savedSearch = savedSearches[i];
                var iconUrl = savedSearch.iconUrl;

                if (_isAnOldIcon(iconUrl)) {
                    savedSearch.iconUrl = _migrateOldIcon(iconUrl);
                }
            }
            localStorage.setItem(
                LOCAL_STORAGE_KEYS.SAVED_SEARCHES,
                JSON.stringify(savedSearches)
            );
        }
    }

    /**
     * Called after the instance has been mounted, where el is replaced by the newly created vm.$el.
     * If the root instance is mounted to an in-document element, vm.$el will also be in-document when mounted is called.
     *
     * Note that mounted does not guarantee that all child components have also been mounted.
     * If you want to wait until the entire view has been rendered, you can use vm.$nextTick inside of mounted
     */
    function mounted() {
        //1 - Native event Listeners
        document.addEventListener("mousedown", this.onMousedownOutside);

        //2 - Vue eventbus Listeners
        this.$eventBus.$on(
            EVENT_NAMES_LISTEN.MAIN_SEARCH_SHOW_PLACE,
            (payload) => {
                _showEvent(payload, this);
            }
        );
        this.$eventBus.$on(EVENT_NAMES_LISTEN.USER_JSP_SHOW_USER, (payload) => {
            _showEvent(payload, this);
        });
        this.$eventBus.$on(EVENT_NAMES_LISTEN.MAIN_SEARCH_CLEAR_PLACE, () => {
            _clearEvent(this);
        });

        //3 - Init
        this.recentSearches[0].items = _fetchRecentSearches();
        this.readScreenDimensions();

        //4 - After the DOM is mounted
        this.$nextTick(function () {
            //4.1 - Detect screen resize
            window.addEventListener("resize", (event) => {
                this.readScreenDimensions();
            });
        });
    }

    function beforeDestroy() {
        //1 - Native event Listeners
        document.removeEventListener("mousedown", this.onMousedownOutside);

        //2 - Vue eventbus Listeners
        this.$eventBus.$off(EVENT_NAMES_LISTEN.SHOW_PLACE);
        this.$eventBus.$off(EVENT_NAMES_LISTEN.CLEAR_PLACE);
    }

    /**
     * -------------------------
     * ---- PRIVATE METHODS ----
     * -------------------------
     * Remember to use .call() if you need access to Vue scope!
     */

    /**
     * Fetch the last recent searches from the datastore (localstorage)
     * @private
     */
    function _fetchRecentSearches() {
        var savedSearchesString = localStorage.getItem(
            LOCAL_STORAGE_KEYS.SAVED_SEARCHES
        );

        var results = [];
        if (savedSearchesString) {
            results = JSON.parse(savedSearchesString);
        }

        return results;
    }

    /**
     * Save the last recent search in the datastore (localstorage)
     * @param lastSearch Item DTO
     * @param recentSearches array of Item DTOs
     * @private
     */
    function _saveRecentSearch(lastSearch, recentSearches) {
        //Iterate removing duplicate
        var indexDuplicate = -1;
        for (var i = 0; i < recentSearches.length; i++) {
            var item = recentSearches[i];
            if (item.url == lastSearch.url) {
                indexDuplicate = i;
                break;
            }
        }
        if (indexDuplicate > -1) {
            recentSearches.splice(indexDuplicate, 1);
        }

        if (recentSearches.length >= MAX_SAVED_SEARCHES) {
            recentSearches.pop();
        }

        recentSearches.unshift(lastSearch);
        localStorage.setItem(
            LOCAL_STORAGE_KEYS.SAVED_SEARCHES,
            JSON.stringify(recentSearches)
        );
    }

    /**
     * Send data to the server to store what the user has selected
     * NOTE: if textQuery is '', means the click comes from a saved search
     * @param item DTO
     * @private
     */
    function _logSearchMetrics(queryText, item) {
        var elements = {
            textQuery: queryText,
            clickedElement: "" + item.id,
            filterSearch: window.location.href,
        };

        //Add optional values to detect if is a place from geocoder
        if (item.sw != null && item.ne != null) {
            elements.isGeocoderPlace = true;
            elements.ne = item.ne;
            elements.sw = item.sw;
        }

        try {
            var encoded = btoa(encodeURI(JSON.stringify(elements)));
            GLOBAL_SEARCH.METHODS.ajax_get(
                AJAX_URLS.GEOCODE_WHATISCLICKED + encoded
            );
        } catch (e) {
            //Non latin characters cannot be send by url. TODO: change the endpoint to work with POST.
        }
    }

    /**
     * Search places using geocoder
     */
    function _searchPlaces(i18n) {
        this.isLoadingResults = true;

        //debouncer, we wait some time after last input
        if (this.search_geocoder_timeout) {
            clearTimeout(this.search_geocoder_timeout);
        }

        this.search_geocoder_timeout = setTimeout(() => {
            //Fetch places

            var url =
                AJAX_URLS.GEOCODE_SEARCH +
                encodeURIComponent(this.text) +
                "&maxRows=25&inclBbox=true&lang=" +
                isoLang;

            GLOBAL_SEARCH.METHODS.ajax_get(
                url,
                (data) => {
                    this.placesSearch =
                        GLOBAL_SEARCH.METHODS.processGeocoderResponse(
                            this.text,
                            data,
                            i18n,
                            false
                        );
                    //We read again if the footer has changed height
                    this.readHeaderAndFooterDimensions();
                    this.isLoadingResults = false;

                    //scroll to top of results
                    this.$nextTick(() => {
                        if (this.$refs.scrollbarContainer != null) {
                            this.$refs.scrollbarContainer.scrollTop = 0;
                        }
                    });
                },
                (error) => {
                    this.isLoadingResults = false;
                }
            );
        }, 400);
    }

    /**
     * Do a search using the location
     * @param position
     * @private
     */
    function _nearbyTrailsSearchOK(position, vueInstance) {
        var bbox = GLOBAL_SEARCH.METHODS.getBoundingBox(
            parseFloat(position.coords.latitude),
            parseFloat(position.coords.longitude),
            NEARBY_TRAILS_DISTANCE_BBOX_METERS
        );
        var sw = bbox[2];
        var ne = bbox[1];
        if (typeof INJECT !== "undefined" && INJECT.isWorldMap === true) {
            //don't reload screen
            var payload = {
                place: null,
                sw: sw,
                ne: ne,
            };
            vueInstance.$eventBus.$emit(EVENT_NAMES_FIRE.SEARCH_PLACE, payload);
        } else {
            window.location.href =
                "/wikiloc/map.do?sw=" + sw + "&ne=" + ne + "&page=1";
        }

        //Close modal
        vueInstance.closeModal();
    }

    /**
     * Erro handling when using the location
     * @param error
     * @private
     */
    function _nearbyTrailsSearchKO(error) {
        window.location.href = "/wikiloc/map.do";
    }

    /**
     * Do a focus event forcing it to work on iOS and Android too
     * NOTE: requires fake focus hack: https://stackoverflow.com/questions/12204571/mobile-safari-javascript-focus-method-on-inputfield-only-works-with-click
     * @private
     *
     * @param targetInput the DOM element to focus
     */
    function _focusResponsive(targetInput) {
        // create invisible dummy input to receive the focus first
        const fakeInput = document.createElement("input");
        fakeInput.setAttribute("type", "text");
        fakeInput.style.position = "absolute";
        fakeInput.style.opacity = 0;
        fakeInput.style.height = 0;
        fakeInput.style.fontSize = "16px"; // disable auto zoom

        // you may need to append to another element depending on the browser's auto
        // zoom/scroll behavior
        document.body.prepend(fakeInput);

        // focus so that subsequent async focus will work
        fakeInput.focus();

        setTimeout(() => {
            // now we can focus on the target input
            targetInput.focus();

            // cleanup
            fakeInput.remove();
        }, 1000);
    }

    /**
     * Enable or disable global document scroll.
     *
     * WARN: It breaks the encapsulation of the component, but it is the only way
     * to block the scroll for the modals
     * We need to do it in the first wrapper element on mobiles
     *
     * @param enable: true/false to enable or disable the document scroll
     */
    function _enableDocumentScroll(enable) {
        let overflow = enable ? "auto" : "hidden";
        let position = enable ? "" : "fixed";

        //HTML tag
        document.documentElement.style.overflow = overflow;
        document.documentElement.style.position = position;

        //BODY tag
        document.body.style.overflow = overflow;
        document.body.style.position = position;
    }

    /**
     * Event handler for showPlace or showUser
     * @param payload with {text: ...}
     */
    function _showEvent(payload, vueInstance) {
        var text = payload.text;
        if (vueInstance.text != text) {
            vueInstance.text = text;
        }
    }

    /**
     * Event handler for :clearPlace
     * @private
     */
    function _clearEvent(vueInstance) {
        vueInstance.text = "";
        vueInstance.placesSearch = [];
    }

    /**
     * Check if an icon it's old
     * @param iconUrl
     * @returns {boolean} true if the iconUrl has an old icon, otherwise false
     */
    function _isAnOldIcon(iconUrl) {
        var filename = iconUrl.substr(iconUrl.lastIndexOf("/") + 1);
        var oldIcons = [
            "icon_area.png",
            "07_geocoder_area.png",
            "icon_city.png",
            "01_geocoder_city.png",
            "icon_lake.png",
            "16_lake.png",
            "icon_mountain.png",
            "35_mountain-summit.png",
            "icon_pin.png",
            "02_geocoder_marker.png",
            "icon_river.png",
            "30_river.png",
            "icon_valley_forest.png",
            "03_geocoder_valley_forest.png",
            "text_icon.svg",
            "user_icon.svg",
            "35_mountain-summit.svg",
            "02_beach.svg",
            "20_mountain_pass.svg",
            "01_geocoder_city.svg",
            "16_lake.svg",
            "30_river.svg",
            "21_cave.svg",
            "40_waterfall.svg",
            "37_tree.svg",
            "03_geocoder_valley_forest.svg",
            "18_mine.svg",
            "23_park.svg",
            "07_geocoder_area.svg",
            "38_tunnel.svg",
            "03_birdwatching.svg",
            "04_bridge.svg",
            "05_museum.svg",
            "06_bus_stop.svg",
            "08_camping.svg",
            "09_castle.svg",
            "12_ferry.svg",
            "15_intersection.svg",
            "18_mine.svg",
            "24_parking.svg",
            "27_provisioning.svg",
            "29_shelter.svg",
            "31_archaeological_place.svg",
            "32_religious_site.svg",
            "34_spa.svg",
            "36_train_stop.svg",
            "41_ruins.svg",
            "42_sport_installation.svg",
            "22_panorama.png",
            "02_geocoder_marker.svg",
        ];
        return oldIcons.indexOf(filename) > -1;
    }

    /**
     * Replace the icon url for the new one
     * @param iconUrl
     * @returns {string} the new icon url
     */
    function _migrateOldIcon(iconUrl) {
        var filename = iconUrl.substr(iconUrl.lastIndexOf("/") + 1);

        switch (filename) {
            case "text_icon.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/search/text_icon_gray.svg";
            case "user_icon.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/search/user_icon_gray.svg";
            case "icon_area.png":
            case "07_geocoder_area.png":
            case "07_geocoder_area.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/geocoder/geocoder_area.svg";
            case "icon_city.png":
            case "01_geocoder_city.png":
            case "01_geocoder_city.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/geocoder/geocoder_city.svg";
            case "icon_pin.png":
            case "02_geocoder_marker.png":
            case "02_geocoder_marker.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/geocoder/geocoder_marker.svg";
            case "icon_valley_forest.png":
            case "03_geocoder_valley_forest.png":
            case "03_geocoder_valley_forest.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/geocoder/geocoder_valley_forest.svg";
            case "21_cave.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/102.svg";
            case "40_waterfall.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/91.svg";
            case "37_tree.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/34.svg";
            case "23_park.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/93.svg";
            case "38_tunnel.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/131.svg";
            case "03_birdwatching.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/119.svg";
            case "04_bridge.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/73.svg";
            case "05_museum.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/123.svg";
            case "06_bus_stop.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/127.svg";
            case "08_camping.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/23.svg";
            case "09_castle.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/100.svg";
            case "12_ferry.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/129.svg";
            case "15_intersection.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/74.svg";
            case "18_mine.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/103.svg";
            case "24_parking.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/32.svg";
            case "27_provisioning.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/99.svg";
            case "29_shelter.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/30.svg";
            case "31_archaeological_place.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/35.svg";
            case "32_religious_site.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/104.svg";
            case "34_spa.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/124.svg";
            case "36_train_stop.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/128.svg";
            case "41_ruins.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/96.svg";
            case "42_sport_installation.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/130.svg";
            case "22_panorama.png":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/10.svg";
            case "icon_lake.png":
            case "16_lake.png":
            case "16_lake.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/71.svg";
            case "20_mountain_pass.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/94.svg";
            case "02_beach.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/97.svg";
            case "icon_mountain.png":
            case "35_mountain-summit.png":
            case "35_mountain-summit.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/33.svg";
            case "icon_river.png":
            case "30_river.png":
            case "30_river.svg":
                return "https://sc.wklcdn.com/wikiloc/assets/styles/images/pictograms/round-background/gray/72.svg";
        }
    }

    ///////////////////////////////////////////////////////////////////////
    //A Vue Main (check for multiple instances)
    if (document.getElementsByClassName("component-search-box").length > 0) {
        new Vue({
            el: ".component-search-box", //link to id="search-box" DOM element
            data: {
                i18n: INJECT_HEADER.i18n,
                alwaysDesktopMode: false,
            },
            template: `
       <search-box :i18n="i18n" :alwaysDesktopMode="alwaysDesktopMode"></search-box>
`,
        });
    }
})();
